import { Button, Container, Image, Nav, Navbar } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import { useEffect, useState } from "react";
import { ethers } from "ethers";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { useAuth } from "../../context/AuthContext";

export default function Header() {
  const { web3Login, address, accessToken, logout } = useAuth();

  return (
    <Navbar collapseOnSelect expand="lg" className="Header">
      <Container>
        <NavLink className="nav-link" to="/">
          <Image src={logo} alt="logo" width="70" />
          <span className="text-main-secondary logo-title">ESPRESSO</span>
        </NavLink>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
          <Nav className="mx-3">
            <ConnectButton />
            {accessToken == 'null' && address ? (
              <Button
                className="ms-3"
                variant="outline-light"
                onClick={web3Login}
              >
                Web3 Login
              </Button>
            ) : null}
            {accessToken !== 'null' ? (
              <Button className="ms-3" variant="outline-light" onClick={logout}>
                Logout
              </Button>
            ) : null}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
