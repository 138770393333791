import { Table, Accordion, Row, Col, Button, Form, Image, InputGroup } from "react-bootstrap";
import eyeIcon from '../../assets/images/icons/eye.svg';
import trashIcon from '../../assets/images/icons/trash.svg';
import clipboardIcon from '../../assets/images/icons/clipboard.svg';
import { useState, useEffect } from "react";
import { useMain } from "../../context/MainContext";
import { useAuth } from "../../context/AuthContext";

export const SettingsTabContent = () => {

    const { currentWallet, currentNetwork, changeCurrentWalletState, changeCurrentNetworkState, getUserWallets, generateWallet, importWallet, deleteWallet, getCurrentWalletId, updateCurrentWallet, getNetworkChoices, getCurrentNetwork, updateCurrentNetwork, getCurrentWallet } = useMain();
    const { accessToken } = useAuth()

    const [wallets, setWallets] = useState([])
    const [showImportForm, setShowImportForm] = useState(false);
    const [privateKey, setPrivateKey] = useState("");
    const [privateKeyVisibility, setPrivateKeyVisibility] = useState({});
    //const [activeWalletId, setActiveWalletId] = useState(null);
    const [networkChoices, setNetworkChoices] = useState([]);
    //const [activeNetwork, setActiveNetwork] = useState('ethereum');

    useEffect(() => {
        fetchWallets();
        //fetchActiveWalletId();
        fetchActiveWallet();
        fetchNetworkChoices();
        fetchActiveNetwork();
    }, [accessToken]);

    // useEffect(() => {
    //     console.log("Updated network choices in state:", networkChoices);
    // }, [networkChoices]);


    const [type, setType] = useState(false);
    const [type1, setType1] = useState(false);
    const [type2, setType2] = useState(false);
    const [type3, setType3] = useState(false);
    const [type4, setType4] = useState(false);

    const fetchWallets = async () => {
        try {
            const response = await getUserWallets();
            const initialVisibility = {};
            if(response){
                setWallets(response);
                response.forEach(wallet => {
                    initialVisibility[wallet.id] = false;
                });
                setPrivateKeyVisibility(initialVisibility);
            }
        } catch (error) {
            console.error("Error fetching wallets:", error);
            setWallets([]);
        }
    };

    // const fetchActiveWalletId = async () => {
    //     const currentWalletId = await getCurrentWalletId();
    //     setActiveWalletId(currentWalletId);
    // };

    const fetchActiveWallet = async () => {
        const currentWallet = await getCurrentWallet();
        changeCurrentWalletState(currentWallet);
    };

    const fetchActiveNetwork = async () => {
        const network = await getCurrentNetwork();
        //setActiveNetwork(network);
        changeCurrentNetworkState(network);
    };

    const fetchNetworkChoices = async () => {
        const choices = await getNetworkChoices();
        if(choices){
            setNetworkChoices(choices);
        }
        
    };

    const handleActiveWalletChange = async (walletId) => {
        try {
          await updateCurrentWallet(walletId);
          //setActiveWalletId(walletId);
          changeCurrentWalletState(await getCurrentWallet())
        } catch (error) {
          console.error("Error updating active wallet:", error);
        }
    };

    const handleActiveNetworkChange = async (network) => {
        try {
          await updateCurrentNetwork(network);
          //setActiveNetwork(network);
          changeCurrentNetworkState(network);
        } catch (error) {
          console.error("Error updating active wallet:", error);
        }
    };

    const toggleImportForm = () => {
        setShowImportForm(!showImportForm);
    };      

    const handlePrivateKeyInputChange = (event) => {
        setPrivateKey(event.target.value);
    };
    
    const handleImportSubmit = async (event) => {
        event.preventDefault();
        console.log("calling import");
        await importWallet(privateKey);
        toggleImportForm();
        await fetchWallets();
    };

    const handleGenerate = async () => {
        console.log('calling generate');
        await generateWallet();
        await fetchWallets();
    }

    const handleDeleteWallet = async (walletId) => {
        try {
          await deleteWallet(walletId);
          await fetchWallets();
        } catch (error) {
          console.error("Error deleting wallet:", error);
        }
    };

    return (
        <div className="SettingsTabContent p-3 p-md-5">
            <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                    <Accordion.Header>Wallets</Accordion.Header>
                    <Accordion.Body className="py-5">
                        <Row>
                            <Col sm={12} md={6} className="mb-5">
                                <Button onClick={async () => await handleGenerate()} className="main-button w-100">Generate</Button>
                            </Col>
                            <Col sm={12} md={6} className="mb-5">
                                <Button className="main-button w-100" onClick={toggleImportForm}>Import</Button>
                                {showImportForm && (
                                    <Form onSubmit={handleImportSubmit}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Enter private key</Form.Label>
                                        <Form.Control
                                        type="text"
                                        placeholder="Private key"
                                        value={privateKey}
                                        onChange={handlePrivateKeyInputChange}
                                        />
                                    </Form.Group>
                                    <Button variant="primary" type="submit">
                                        Submit
                                    </Button>
                                    </Form>
                                )}
                            </Col>
                        </Row>
                        <Table responsive>
                            <thead>
                                <tr>
                                    <th>No</th>
                                    <th>Address/Public Key</th>
                                    <th>Active</th>
                                    <th></th>
                                </tr>
                            </thead>

                            <tbody>
                            {wallets.map((wallet, index) => (
                                <tr key={wallet.id}>
                                <td className="align-middle ps-2">{index + 1}</td>
                                <td className="ps-2">
                                    <div>{wallet.public_key}</div>
                                    <Col md={9}>
                                    <InputGroup className="setting-public-key">
                                        <Form.Control
                                        aria-label="Private Key"
                                        readOnly
                                        disabled
                                        className="border-0 p-0"
                                        type={privateKeyVisibility[wallet.id] ? "text" : "password"}
                                        value={wallet.private_key}
                                        />
                                        <Button
                                        className="border-0 p-0 m-0 me-3"
                                        onClick={() => setPrivateKeyVisibility({
                                            ...privateKeyVisibility,
                                            [wallet.id]: !privateKeyVisibility[wallet.id]
                                        })}
                                        >
                                        <Image src={eyeIcon} width="15" />
                                        </Button>
                                    </InputGroup>
                                    </Col>
                                </td>
                                <td className="align-middle ps-2">
                                    <Form.Check
                                        inline
                                        name="group1"
                                        type='radio'
                                        checked={currentWallet ? wallet.id === currentWallet.id : false}
                                        onChange={() => handleActiveWalletChange(wallet.id)}
                                    />
                                </td>
                                <td className="align-middle text-end">
                                    <Button className="border-0" onClick={async () => await handleDeleteWallet(wallet.id)}><Image src={trashIcon} alt="remove" width="25" /></Button>
                                </td>
                                </tr>
                            ))}
                            </tbody>

                        </Table>
                    </Accordion.Body>
                </Accordion.Item>
                

                <Accordion.Item eventKey="1">
                    <Accordion.Header>Networks</Accordion.Header>
                    <Accordion.Body className="py-5">
                        <Row>
                            <Col sm={12}>
                                <Table responsive>
                                    <thead>
                                        <tr>
                                            <th>Networks</th>
                                            <th className="text-end">Active</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {networkChoices.map((network, index) => (
                                            <tr key={index}>
                                                <td className="align-middle ps-2">{network}</td>
                                                <td className="align-middle text-end">
                                                    <Form.Check
                                                        inline
                                                        name="networkGroup"
                                                        type='radio'
                                                        checked={network === currentNetwork}
                                                        onChange={() => handleActiveNetworkChange(network)}
                                                    />
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="2">
                    <Accordion.Header>Link to Terminal</Accordion.Header>
                    <Accordion.Body className="py-5">
                        <Row>
                            <Col sm={12}>
                                <div className="link-to-terminal-description mb-3">Paste the following key in your terminal to link this telegram account to your profile</div>
                                <div className="link-to-terminal-key mb-3 d-flex align-items-center"><span>9895be89-66ad-4e80-807b-3150367acd9e</span><span className="ms-2"><Image src={clipboardIcon} alt="copy" width="15" /></span></div>
                            </Col>
                        </Row>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            
        </div>
    )
}