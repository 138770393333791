import { Col, Row, InputGroup, Form, DropdownButton, Dropdown, Image, Tooltip, OverlayTrigger, Button, Modal } from "react-bootstrap";
import InfoIcon from "../../assets/images/icons/tooltip.svg";
import { useMain } from "../../context/MainContext";
import { useState, useEffect } from "react";
import { useAuth } from "../../context/AuthContext";

const DeploymentStatusPopup = ({ show, status, handleClose, errorMessage }) => {
    const getStatusEmoji = (statusValue) => statusValue ? '✅' : '⏳';

    const copyToClipboard = text => {
        navigator.clipboard.writeText(text);
    };
  
    return (
      <Modal className="deploymentStatusPopup" show={show} onHide={handleClose} backdrop={status.state_completed || status.state_completed_with_errors ? true : 'static'}>
        <Modal.Header closeButton={status.state_completed || status.state_completed_with_errors}>
          <Modal.Title className="text-main-secondary"> Deployment Status</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {status.state_completed_with_errors && <p className="text-danger">{errorMessage}</p>}
          {status.state_completed && <p className="text-success">Deployment Completed Successfully!</p>}
          {!status.state_completed && !status.state_completed_with_errors && (
            <>
                <p className="text-main-primary">Compile {getStatusEmoji(status.state_compile)}</p>
                <p className="text-main-primary">Deploy {getStatusEmoji(status.state_deploy)}</p>
                {status.tx_hash && (
                    <p style={{ fontSize: '80%' }}>
                        <p style={{ color: '#FF2FBE' }}>Tx hash:</p>
                        <span style={{ cursor: 'pointer', color:'#0000EE', overflow: 'hidden', textOverflow: 'ellipsis', display: 'inline-block', maxWidth: '90%' }} onClick={() => copyToClipboard(status.tx_hash)}>{status.tx_hash}</span>
                    </p>
                )}
                {status.address && (
                    <p style={{ fontSize: '80%' }}>
                        <p style={{ color: '#FF2FBE' }}>Address:</p>
                        <span style={{ cursor: 'pointer', color:'#0000EE', overflow: 'hidden', textOverflow: 'ellipsis', display: 'inline-block', maxWidth: '90%' }} onClick={() => copyToClipboard(status.address)}>{status.address}</span>
                    </p>
                )}
                <p className="text-main-primary">Get pair address {getStatusEmoji(status.state_pair_address)}</p>
                <p className="text-main-primary">Approve router {getStatusEmoji(status.state_approve_router)}</p>
                <p className="text-main-primary">Add liquidity {getStatusEmoji(status.state_add_liquidity)}</p>
                <p className="text-main-primary">Remove limits {getStatusEmoji(status.state_remove_limits)}</p>
                <p className="text-main-primary">Approve locker {getStatusEmoji(status.state_approve_locker)}</p>
                <p className="text-main-primary">Lock liquidity {getStatusEmoji(status.state_lock_lp)}</p>
                <p className="text-main-primary">Renounce ownership {getStatusEmoji(status.state_renounce_ownership)}</p>
                <p className="text-main-primary">Verify contracts {getStatusEmoji(status.state_verify)}</p>
            </>
          )}
        </Modal.Body>
      </Modal>
    );
};


export const DeployTabContent = () => {

    const {accessToken} = useAuth();
    const { updateCurrentToken, getCurrentWallet, getCurrentNetwork, getCurrentToken, deployCurrentToken, getCurrentTokenStatus } = useMain();
    const [formData, setFormData] = useState({
        symbol: '',
        name: '',
        class_name: '',
        supply: '',
        initial_buy_tax: '',
        initial_sell_tax: '',
        initial_tax_threshold: '',
        buy_tax: '',
        sell_tax: '',
        burn: '',
        tax_receiver: '',
        pre_mint: '',
        eth_lp: '',
        lp_lock: '',
        max_token_per_wallet: '',
        max_token_per_transaction: '',
        open_trade_delay: '',
        remove_limits_delay: '',
        telegram_link: '',
        discord_link: '',
        twitter_link: '',
        website_link: ''
    });
    const [validationErrors, setValidationErrors] = useState({});
    const [showSummary, setShowSummary] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [deploymentStatus, setDeploymentStatus] = useState({
        state_compile: false,
        state_deploy: false,
        state_pair_address: false,
        state_approve_router: false,
        state_add_liquidity: false,
        state_remove_limits: false,
        state_approve_locker: false,
        state_lock_lp: false,
        state_renounce_ownership: false,
        state_verify: false,
        state_completed: false,
        state_completed_with_errors: false,
        state_error_message: '',
        tx_hash: '',
        address: ''
    });
    const [intervalId, setIntervalId] = useState(null);

    // useEffect(() => {
    //     console.log("Updated formData: ", formData);
    // }, [formData]);

    useEffect(() => {
        fetchCurrentToken();

        checkAndOpenPopup();

        return () => {
            clearInterval(intervalId);
        };
    }, [accessToken]);


    const fetchCurrentToken = async () => {
        try {
            const tokenData = await getCurrentToken();
            if (tokenData) {
                setFormData({
                    symbol: tokenData.symbol !== null && tokenData.symbol !== undefined ? String(tokenData.symbol) : '',
                    name: tokenData.name !== null && tokenData.name !== undefined ? String(tokenData.name) : '',
                    class_name: tokenData.class_name !== null && tokenData.class_name !== undefined ? String(tokenData.class_name) : '',
                    supply: tokenData.supply !== null && tokenData.supply !== undefined ? String(tokenData.supply) : '',
                    initial_buy_tax: tokenData.initial_buy_tax !== null && tokenData.initial_buy_tax !== undefined ? String(tokenData.initial_buy_tax) : '',
                    initial_sell_tax: tokenData.initial_sell_tax !== null && tokenData.initial_sell_tax !== undefined ? String(tokenData.initial_sell_tax) : '',
                    initial_tax_threshold: tokenData.initial_tax_threshold !== null && tokenData.initial_tax_threshold !== undefined ? String(tokenData.initial_tax_threshold) : '',
                    buy_tax: tokenData.buy_tax !== null && tokenData.buy_tax !== undefined ? String(tokenData.buy_tax) : '',
                    sell_tax: tokenData.sell_tax !== null && tokenData.sell_tax !== undefined ? String(tokenData.sell_tax) : '',
                    burn: tokenData.burn !== null && tokenData.burn !== undefined ? String(tokenData.burn) : '',
                    tax_receiver: tokenData.tax_receiver !== null && tokenData.tax_receiver !== undefined ? String(tokenData.tax_receiver) : '',
                    pre_mint: tokenData.pre_mint !== null && tokenData.pre_mint !== undefined ? String(tokenData.pre_mint) : '',
                    eth_lp: tokenData.eth_lp !== null && tokenData.eth_lp !== undefined ? String(tokenData.eth_lp) : '',
                    lp_lock: tokenData.lp_lock !== null && tokenData.lp_lock !== undefined ? String(tokenData.lp_lock) : '',
                    max_token_per_wallet: tokenData.max_token_per_wallet !== null && tokenData.max_token_per_wallet !== undefined ? String(tokenData.max_token_per_wallet) : '',
                    max_token_per_transaction: tokenData.max_token_per_transaction !== null && tokenData.max_token_per_transaction !== undefined ? String(tokenData.max_token_per_transaction) : '',
                    open_trade_delay: tokenData.open_trade_delay !== null && tokenData.open_trade_delay !== undefined ? String(tokenData.open_trade_delay) : '',
                    remove_limits_delay: tokenData.remove_limits_delay !== null && tokenData.remove_limits_delay !== undefined ? String(tokenData.remove_limits_delay) : '',
                    telegram_link: tokenData.telegram_link !== null && tokenData.telegram_link !== undefined ? String(tokenData.telegram_link) : '',
                    discord_link: tokenData.discord_link !== null && tokenData.discord_link !== undefined ? String(tokenData.discord_link) : '',
                    twitter_link: tokenData.twitter_link !== null && tokenData.twitter_link !== undefined ? String(tokenData.twitter_link) : '',
                    website_link: tokenData.website_link !== null && tokenData.website_link !== undefined ? String(tokenData.website_link) : ''
                });
            }
        } catch (error) {
            console.error("Error fetching current token:", error);
            // Handle error appropriately
        }
    };

    const handleChange = async (key, value) => {
        setFormData({ ...formData, [key]: value });
        setValidationErrors({ ...validationErrors, [key]: '' });
        // if(key == 'symbol') {await updateCurrentToken({symbol:value})}
        // if(key == 'name') {await updateCurrentToken({name:value})}
        // if(key == 'class_name') {await updateCurrentToken({class_name:value})}
        // if(key == 'supply') {await updateCurrentToken({supply:value})}
        // if(key == 'pre_mint') {await updateCurrentToken({pre_mint:value})}
        // if(key == 'tax_receiver') {await updateCurrentToken({tax_receiver:value})}
        // if(key == 'initial_buy_tax') {await updateCurrentToken({initial_buy_tax:value})}
        // if(key == 'initial_sell_tax') {await updateCurrentToken({initial_sell_tax:value})}
        // if(key == 'initial_tax_threshold') {await updateCurrentToken({initial_tax_threshold:value})}
        // if(key == 'buy_tax') {await updateCurrentToken({buy_tax:value})}
        // if(key == 'sell_tax') {await updateCurrentToken({sell_tax:value})}
        // if(key == 'max_token_per_wallet') {await updateCurrentToken({max_token_per_wallet:value})}
        // if(key == 'max_token_per_transaction') {await updateCurrentToken({max_token_per_transaction:value})}
        // if(key == 'burn') {await updateCurrentToken({burn:value})}
        // if(key == 'eth_lp') {await updateCurrentToken({eth_lp:value})}
        // if(key == 'lp_lock') {await updateCurrentToken({lp_lock:value})}
        // if(key == 'telegram_link') {await updateCurrentToken({telegram_link:value})}
        // if(key == 'discord_link') {await updateCurrentToken({discord_link:value})}
        // if(key == 'twitter_link') {await updateCurrentToken({twitter_link:value})}
        // if(key == 'website_link') {await updateCurrentToken({website_link:value})}
        // if(key == 'open_trade_delay') {await updateCurrentToken({open_trade_delay:value})}
        // if(key == 'remove_limits_delay') {await updateCurrentToken({remove_limits_delay:value})}
        // if(key == 'network') {await updateCurrentToken({network:value})}
    };

    const handleBlur = async (key) => {
        try {
            const updateData = { [key]: formData[key] };
            console.log(`Updating token field ${key}:`, updateData);
            await updateCurrentToken(updateData);
            setValidationErrors({ ...validationErrors, [key]: '' });
        } catch (error) {
            console.error(`Error updating token field ${key}:`, error);
            //setValidationErrors({ ...validationErrors, [key]: "Invalid input" });
            setValidationErrors({ ...validationErrors, [key]: error.message });
        }
    };

    const handleDropdownSelect = async (key, value) => {
        try {
            const updateData = { [key]: value };
            console.log(`Updating token field ${key}:`, updateData);
            await updateCurrentToken(updateData);
            // Handle success, maybe with a toast notification
        } catch (error) {
            console.error(`Error updating token field ${key}:`, error);
            // Handle error, maybe revert the change in the UI or show an error message
        }
    };

    const handleSelectCurrentWalletForTaxReceiver = async () => {
        try {
            const wallet = await getCurrentWallet();
            if (wallet && wallet.public_key) {
                handleChange('tax_receiver', wallet.public_key);
            }
            handleDropdownSelect('tax_receiver', wallet.public_key)
        } catch (error) {
            console.error("Error fetching current wallet:", error);
            // Handle error appropriately
        }
    };

    const handleReviewButton = async () => {
        try {
            // await updateCurrentToken({
            //     network: await getCurrentNetwork(),
            // });
            //await deployCurrentToken();
            //console.log("Token deployed succesfully")
            setShowSummary(true);
        } catch (error) {
            console.error("Error:", error);
            // Add error handling (e.g., displaying error message)
        }
    };

    const handleBackButton = async () => {
        setShowSummary(false);
    };

    const handleDeployButton = async () => {
        try {
            deployCurrentToken();
            setShowPopup(true);
            const id = setInterval(fetchDeploymentStatus, 2000);
            setIntervalId(id);
        } catch (error) {
            console.error("Error deploying token:", error);
        }
    };

    const fetchDeploymentStatus = async () => {
        const newStatus = await getCurrentTokenStatus();
        //console.log("Token status: ", newStatus)
        setDeploymentStatus(newStatus);
    
        if (newStatus.state_completed || newStatus.state_completed_with_errors) {
          clearInterval(intervalId);
        }
    };

    const checkAndOpenPopup = async () => {
        const currentStatus = await getCurrentTokenStatus();
        if (currentStatus && currentStatus.state_compile && !currentStatus.state_completed && !currentStatus.state_completed_with_errors) {
            setShowSummary(true);
            setShowPopup(true);
            startPolling();
        }
    };

    const startPolling = () => {
        const id = setInterval(fetchDeploymentStatus, 2000);
        setIntervalId(id);
    };

    const hasValidationErrors = () => {
        return Object.values(validationErrors).some(error => error !== '');
    };

    const renderDeploymentSummary = () => {
        return (
            <div className="DeployTabContent p-3 p-md-5">
                <div className="tab-title text-center"><span className="text-main-primary">Review</span> <span className="text-main-secondary">Token</span></div>
                <hr className="secondary-hr my-5" />
                <Row>
                    <Col sm={12} md={6} className="mb-5">
                        <div className="review-row">
                            <div className="review-key">💲 Symbol:</div> 
                            <div className="review-value">{formData.symbol}</div>
                        </div>
                        <div className="review-row">
                            <div className="review-key">🔠 Name:</div> 
                            <div className="review-value">{formData.name}</div>
                        </div>
                        <div className="review-row">
                            <div className="review-key">🔢 Supply:</div> 
                            <div className="review-value">{formData.supply}</div>
                        </div>
                        <div className="review-row">
                            <div className="review-key">🔤 Class Name:</div> 
                            <div className="review-value">{formData.class_name}</div>
                        </div>
                        <div className="review-row">
                            <div className="review-key">🟢 Initial Buy Tax:</div> 
                            <div className="review-value">{formData.initial_buy_tax}</div>
                        </div>
                        <div className="review-row">
                            <div className="review-key">🔴 Initial Sell Tax:</div> 
                            <div className="review-value">{formData.initial_sell_tax}</div>
                        </div>
                        <div className="review-row">
                            <div className="review-key">⏱ Initial Tax Threshold:</div> 
                            <div className="review-value">{formData.initial_tax_threshold}</div>
                        </div>
                        <div className="review-row">
                            <div className="review-key">🟢 Buy Tax:</div> 
                            <div className="review-value">{formData.buy_tax}</div>
                        </div>
                        <div className="review-row">
                            <div className="review-key">🔴 Sell Tax:</div> 
                            <div className="review-value">{formData.sell_tax}</div>
                        </div>
                        <div className="review-row">
                            <div className="review-key">🔥 Burn:</div> 
                            <div className="review-value">{formData.burn}</div>
                        </div>
                        <div className="review-row">
                            <div className="review-key">📩 Tax Receiver:</div> 
                            <div className="review-value">{`${formData.tax_receiver.slice(0, 5)}...${formData.tax_receiver.slice(-3)}`}</div>
                        </div>
                    </Col>
                    <Col sm={12} md={6} className="mb-5">
                        <div className="review-row">
                            <div className="review-key">💰 Pre Mint Supply:</div> 
                            <div className="review-value">{formData.pre_mint}</div>
                        </div>
                        <div className="review-row">
                            <div className="review-key">💱 ETH LP amount:</div> 
                            <div className="review-value">{formData.eth_lp}</div>
                        </div>
                        <div className="review-row">
                            <div className="review-key">🔒 LP Lock duration:</div> 
                            <div className="review-value">{formData.lp_lock}</div>
                        </div>
                        <div className="review-row">
                            <div className="review-key">🚫 Max token per wallet:</div> 
                            <div className="review-value">{formData.max_token_per_wallet}</div>
                        </div>
                        <div className="review-row">
                            <div className="review-key">🚫 Max token per TX:</div> 
                            <div className="review-value">{formData.max_token_per_transaction}</div>
                        </div>
                        <div className="review-row">
                            <div className="review-key">⏳ Open Trade Delay:</div> 
                            <div className="review-value">{formData.open_trade_delay}</div>
                        </div>
                        <div className="review-row">
                            <div className="review-key">⏳ Remove Limits Delay:</div> 
                            <div className="review-value">{formData.remove_limits_delay}</div>
                        </div>
                        <div className="review-row">
                            <div className="review-key">🦉 Twitter:</div> 
                            <div className="review-value">{formData.twitter_link}</div>
                        </div>
                        <div className="review-row">
                            <div className="review-key">💬 Telegram:</div> 
                            <div className="review-value">{formData.telegram_link}</div>
                        </div>
                        <div className="review-row">
                            <div className="review-key">🌐 Website:</div> 
                            <div className="review-value">{formData.website_link}</div>
                        </div>
                        <div className="review-row">
                            <div className="review-key">👾 Discord:</div> 
                            <div className="review-value">{formData.discord_link}</div>
                        </div>
                    </Col>
                </Row>
                <hr className="secondary-hr my-5" />
                <Row>
                    <Col sm={12} md={3} className="mx-auto mb-5">
                        <Button className="main-button w-100" onClick={handleDeployButton}>Deploy</Button>
                    </Col>
                </Row>
                <DeploymentStatusPopup
                    show={showPopup}
                    status={deploymentStatus}
                    errorMessage={deploymentStatus.state_error_message}
                    handleClose={() => {setShowPopup(false); clearInterval(intervalId);}}
                />
                <Row>
                    <Col sm={12} md={3} className="d-flex justify-content-center align-items-center mx-auto mb-5">
                        <Button className="secondary-button w-100" onClick={handleBackButton}>⬅️ Back</Button>
                    </Col>
                </Row>
            </div>
        );
    };

    const TooltipBody = ({ id, children, title }) => (
        <OverlayTrigger overlay={<Tooltip id={id}>{title}</Tooltip>}>
            <a>{children}</a>
        </OverlayTrigger>
    );

    if(!showSummary) {
        return (
            <div className="DeployTabContent p-3 p-md-5">
                <div className="tab-title text-center"><span className="text-main-primary">Token</span> <span className="text-main-secondary">Parameters</span></div>
                <hr className="secondary-hr my-5" />
                <Row>
                    <Col sm={12} md={6} className="mb-5">
                        <Form.Label>💲 Symbol <TooltipBody title="Type a Symbol for your Token" id="t-1"><Image src={InfoIcon} width="10" /></TooltipBody></Form.Label>
                        <InputGroup className="mb-3">
                            <Col>
                                <Form.Control
                                    //className={validationErrors.symbol ? 'error-border' : ''} 
                                    aria-label="Text input with dropdown button" 
                                    value={formData.symbol}
                                    onChange={(e) => handleChange('symbol', e.target.value)}
                                    onBlur={() => handleBlur('symbol')}
                                />
                                {validationErrors.symbol && (
                                    <div className="error-message">{validationErrors.symbol}</div>
                                )}
                            </Col>
                        </InputGroup>
                    </Col>
                    <Col sm={12} md={6} className="mb-5">
                        <Form.Label>🔠 Name <TooltipBody title="Type a Name for your Token" id="t-1"><Image src={InfoIcon} width="10" /></TooltipBody></Form.Label>
                        <InputGroup className="mb-3">
                            <Col>
                                <Form.Control
                                    //className={validationErrors.name ? 'error-border' : ''} 
                                    aria-label="Text input with dropdown button" 
                                    value={formData.name}
                                    onChange={(e) => handleChange('name', e.target.value)}
                                    onBlur={() => handleBlur('name')}
                                />
                                {validationErrors.name && (
                                    <div className="error-message">{validationErrors.name}</div>
                                )}
                            </Col>
                        </InputGroup>
                    </Col>
                    <Col sm={12} md={6} className="mb-5">
                        <Form.Label>🔢 Supply <TooltipBody title="Choose Supply for your Token" id="t-1"><Image src={InfoIcon} width="10" /></TooltipBody></Form.Label>
                        <InputGroup className="mb-3">
                            <Col>
                                <Form.Control
                                    //className={validationErrors.supply ? 'error-border' : ''} 
                                    aria-label="Text input with dropdown button"
                                    value={formData.supply}
                                    onChange={(e) => handleChange('supply', e.target.value)}
                                    onBlur={() => handleBlur('supply')}
                                    type='number'
                                />
                                {validationErrors.supply && (
                                    <div className="error-message">{validationErrors.supply}</div>
                                )}
                            </Col>
                            <DropdownButton
                                variant="outline-secondary"
                                title=""
                                id="input-group-dropdown-2"
                                align="end"
                            >
                                <Dropdown onSelect={(eventKey) => {handleChange('supply', eventKey); handleDropdownSelect('supply', eventKey)}}>
                                    <Dropdown.Item eventKey="1000000">1 million</Dropdown.Item>
                                    <Dropdown.Item eventKey="10000000">10 million</Dropdown.Item>
                                    <Dropdown.Item eventKey="100000000">100 million</Dropdown.Item>
                                    <Dropdown.Item eventKey="1000000000">1 billion</Dropdown.Item>
                                    <Dropdown.Item eventKey="10000000000">10 billion</Dropdown.Item>
                                    <Dropdown.Item eventKey="100000000000">100 billion</Dropdown.Item>
                                    <Dropdown.Item eventKey="1000000000000">1 trillion</Dropdown.Item>
                                    <Dropdown.Item eventKey="10000000000000">10 trillion</Dropdown.Item>
                                    <Dropdown.Item eventKey="100000000000000">100 trillion</Dropdown.Item>
                                </Dropdown>
                            </DropdownButton>
                        </InputGroup>
                    </Col>
                    <Col sm={12} md={6} className="mb-5">
                        <Form.Label>🔤 Class Name <TooltipBody title="Type a Class Name for your Token: Must be like: TokenNameExample" id="t-1"><Image src={InfoIcon} width="10" /></TooltipBody></Form.Label>
                        <InputGroup className="mb-3">
                            <Col>
                                <Form.Control 
                                    aria-label="Text input with dropdown button" 
                                    value={formData.class_name}
                                    onChange={(e) => handleChange('class_name', e.target.value)}
                                    onBlur={() => handleBlur('class_name')}    
                                />
                                {validationErrors.class_name && (
                                    <div className="error-message">{validationErrors.class_name}</div>
                                )}
                            </Col>
                        </InputGroup>
                    </Col>
                    <Col sm={12} md={6} className="mb-5">
                        <Form.Label>🟢 Initial Buy Tax <TooltipBody title="Choose Initial Buy Tax, if any" id="t-1"><Image src={InfoIcon} width="10" /></TooltipBody></Form.Label>
                        <InputGroup className="mb-3">
                            <Col>
                                <Form.Control 
                                    aria-label="Text input with dropdown button" 
                                    value={formData.initial_buy_tax}
                                    onChange={(e) => handleChange('initial_buy_tax', e.target.value)}
                                    onBlur={() => handleBlur('initial_buy_tax')}
                                    type='number'
                                />
                                {validationErrors.initial_buy_tax && (
                                    <div className="error-message">{validationErrors.initial_buy_tax}</div>
                                )}
                            </Col>
                            <DropdownButton
                                variant="outline-secondary"
                                title=""
                                id="input-group-dropdown-2"
                                align="end"
                            >
                                <Dropdown onSelect={(eventKey) => {handleChange('initial_buy_tax', eventKey); handleDropdownSelect('initial_buy_tax', eventKey)}}>
                                    <Dropdown.Item eventKey='0'>0%</Dropdown.Item>
                                    <Dropdown.Item eventKey='5'>5%</Dropdown.Item>
                                    <Dropdown.Item eventKey='10'>10%</Dropdown.Item>
                                    <Dropdown.Item eventKey='15'>15%</Dropdown.Item>
                                    <Dropdown.Item eventKey='20'>20%</Dropdown.Item>
                                    <Dropdown.Item eventKey='25'>25%</Dropdown.Item>
                                </Dropdown>
                            </DropdownButton>
                        </InputGroup>
                    </Col>
                    <Col sm={12} md={6} className="mb-5">
                        <Form.Label>🔴 Initial Sell Tax <TooltipBody title=" Choose Initial Sell Tax, if any" id="t-1"><Image src={InfoIcon} width="10" /></TooltipBody></Form.Label>
                        <InputGroup className="mb-3">
                            <Col>
                                <Form.Control 
                                    aria-label="Text input with dropdown button" 
                                    value={formData.initial_sell_tax}
                                    onChange={(e) => handleChange('initial_sell_tax', e.target.value)}
                                    onBlur={() => handleBlur('initial_sell_tax')}
                                    type='number'
                                />
                                {validationErrors.initial_sell_tax && (
                                    <div className="error-message">{validationErrors.initial_sell_tax}</div>
                                )}
                            </Col>
                            <DropdownButton
                                variant="outline-secondary"
                                title=""
                                id="input-group-dropdown-2"
                                align="end"
                            >
                                <Dropdown onSelect={(eventKey) => {handleChange('initial_sell_tax', eventKey); handleDropdownSelect('initial_sell_tax', eventKey)}}>
                                    <Dropdown.Item eventKey='0'>0%</Dropdown.Item>
                                    <Dropdown.Item eventKey='5'>5%</Dropdown.Item>
                                    <Dropdown.Item eventKey='10'>10%</Dropdown.Item>
                                    <Dropdown.Item eventKey='15'>15%</Dropdown.Item>
                                    <Dropdown.Item eventKey='20'>20%</Dropdown.Item>
                                    <Dropdown.Item eventKey='25'>25%</Dropdown.Item>
                                </Dropdown>
                            </DropdownButton>
                        </InputGroup>
                    </Col>
                    <Col sm={12} md={6} className="mb-5">
                        <Form.Label>⏱ Initial Tax Threshold <TooltipBody title="Choose after how many buys the normal taxes should be applied:" id="t-1"><Image src={InfoIcon} width="10" /></TooltipBody></Form.Label>
                        <InputGroup className="mb-3">
                            <Col>
                                <Form.Control 
                                    aria-label="Text input with dropdown button"
                                    value={formData.initial_tax_threshold}
                                    onChange={(e) => handleChange('initial_tax_threshold', e.target.value)}
                                    onBlur={() => handleBlur('initial_tax_threshold')}
                                    type='number'
                                />
                                {validationErrors.initial_tax_threshold && (
                                    <div className="error-message">{validationErrors.initial_tax_threshold}</div>
                                )}
                            </Col>
                            <DropdownButton
                                variant="outline-secondary"
                                title=""
                                id="input-group-dropdown-2"
                                align="end"
                            >
                                <Dropdown onSelect={(eventKey) => {handleChange('initial_tax_threshold', eventKey); handleDropdownSelect('initial_tax_threshold', eventKey)}}>
                                    <Dropdown.Item eventKey='0'>0</Dropdown.Item>
                                    <Dropdown.Item eventKey='15'>15</Dropdown.Item>
                                    <Dropdown.Item eventKey='30'>30</Dropdown.Item>
                                    <Dropdown.Item eventKey='45'>45</Dropdown.Item>
                                    <Dropdown.Item eventKey='60'>60</Dropdown.Item>
                                    <Dropdown.Item eventKey='75'>75</Dropdown.Item>
                                </Dropdown>
                            </DropdownButton>
                        </InputGroup>
                    </Col>
                    <Col sm={12} md={6} className="mb-5">
                        <Form.Label>🟢 Buy Tax <TooltipBody title="Choose Buy Tax, if any" id="t-1"><Image src={InfoIcon} width="10" /></TooltipBody></Form.Label>
                        <InputGroup className="mb-3">
                            <Col>
                                <Form.Control 
                                    aria-label="Text input with dropdown button"
                                    value={formData.buy_tax}
                                    onChange={(e) => handleChange('buy_tax', e.target.value)}
                                    onBlur={() => handleBlur('buy_tax')}
                                    type='number'
                                />
                                {validationErrors.buy_tax && (
                                    <div className="error-message">{validationErrors.buy_tax}</div>
                                )}
                            </Col>
                            <DropdownButton
                                variant="outline-secondary"
                                title=""
                                id="input-group-dropdown-2"
                                align="end"
                            >
                                <Dropdown onSelect={(eventKey) => {handleChange('buy_tax', eventKey); handleDropdownSelect('buy_tax', eventKey)}}>
                                    <Dropdown.Item eventKey='0'>0%</Dropdown.Item>
                                    <Dropdown.Item eventKey='1'>1%</Dropdown.Item>
                                    <Dropdown.Item eventKey='2'>2%</Dropdown.Item>
                                    <Dropdown.Item eventKey='3'>3%</Dropdown.Item>
                                    <Dropdown.Item eventKey='4'>4%</Dropdown.Item>
                                    <Dropdown.Item eventKey='5'>5%</Dropdown.Item>
                                </Dropdown>
                            </DropdownButton>
                        </InputGroup>
                    </Col>
                    <Col sm={12} md={6} className="mb-5">
                        <Form.Label>🔴 Sell Tax <TooltipBody title="Choose Sell Tax, if any" id="t-1"><Image src={InfoIcon} width="10" /></TooltipBody></Form.Label>
                        <InputGroup className="mb-3">
                            <Col>
                                <Form.Control 
                                    aria-label="Text input with dropdown button"
                                    value={formData.sell_tax}
                                    onChange={(e) => handleChange('sell_tax', e.target.value)}
                                    onBlur={() => handleBlur('sell_tax')}
                                    type='number'
                                />
                                {validationErrors.sell_tax && (
                                    <div className="error-message">{validationErrors.sell_tax}</div>
                                )}
                            </Col>
                            <DropdownButton
                                variant="outline-secondary"
                                title=""
                                id="input-group-dropdown-2"
                                align="end"
                            >
                                <Dropdown onSelect={(eventKey) => {handleChange('sell_tax', eventKey); handleDropdownSelect('sell_tax', eventKey)}}>
                                    <Dropdown.Item eventKey='0'>0%</Dropdown.Item>
                                    <Dropdown.Item eventKey='1'>1%</Dropdown.Item>
                                    <Dropdown.Item eventKey='2'>2%</Dropdown.Item>
                                    <Dropdown.Item eventKey='3'>3%</Dropdown.Item>
                                    <Dropdown.Item eventKey='4'>4%</Dropdown.Item>
                                    <Dropdown.Item eventKey='5'>5%</Dropdown.Item>
                                </Dropdown>
                            </DropdownButton>
                        </InputGroup>
                    </Col>
                    <Col sm={12} md={6} className="mb-5">
                        <Form.Label>🔥 Burn <TooltipBody title="Choose Burn Percentage for each Transaction, if any" id="t-1"><Image src={InfoIcon} width="10" /></TooltipBody></Form.Label>
                        <InputGroup className="mb-3">
                            <Col>
                                <Form.Control 
                                    aria-label="Text input with dropdown button"
                                    value={formData.burn}
                                    onChange={(e) => handleChange('burn', e.target.value)}
                                    onBlur={() => handleBlur('burn')}
                                    type='number'
                                />
                                {validationErrors.burn && (
                                    <div className="error-message">{validationErrors.burn}</div>
                                )}
                            </Col>
                            <DropdownButton
                                variant="outline-secondary"
                                title=""
                                id="input-group-dropdown-2"
                                align="end"
                            >   
                                <Dropdown onSelect={(eventKey) => {handleChange('burn', eventKey); handleDropdownSelect('burn', eventKey)}}>
                                    <Dropdown.Item eventKey='0'>0%</Dropdown.Item>
                                    <Dropdown.Item eventKey='0.5'>0.5%</Dropdown.Item>
                                    <Dropdown.Item eventKey='1'>1%</Dropdown.Item>
                                    <Dropdown.Item eventKey='2'>2%</Dropdown.Item>
                                    <Dropdown.Item eventKey='2.5'>2.5%</Dropdown.Item>
                                    <Dropdown.Item eventKey='3'>3%</Dropdown.Item>
                                </Dropdown>
                            </DropdownButton>
                        </InputGroup>
                    </Col>
                    <Col sm={12} md={6} className="mb-5">
                        <Form.Label>📩 Tax Receiver <TooltipBody title="Choose which Address will receive Token Taxes" id="t-1"><Image src={InfoIcon} width="10" /></TooltipBody></Form.Label>
                        <InputGroup className="mb-3">
                            <Col>
                                <Form.Control 
                                    aria-label="Text input with dropdown button"
                                    value={formData.tax_receiver}
                                    onChange={(e) => handleChange('tax_receiver', e.target.value)}
                                    onBlur={() => handleBlur('tax_receiver')}
                                />
                                {validationErrors.tax_receiver && (
                                    <div className="error-message">{validationErrors.tax_receiver}</div>
                                )}
                            </Col>
                            <DropdownButton
                                variant="outline-secondary"
                                title=""
                                id="input-group-dropdown-2"
                                align="end"
                            >   
                                <Dropdown.Item onClick={handleSelectCurrentWalletForTaxReceiver}>Current Wallet</Dropdown.Item>
                            </DropdownButton>
                        </InputGroup>
                    </Col>
                    <Col sm={12} md={6} className="mb-5">
                        <Form.Label>💰 Pre Mint Supply <TooltipBody title="Choose how many Tokens you want to allocate to yourself, as a percentage of the supply" id="t-1"><Image src={InfoIcon} width="10" /></TooltipBody></Form.Label>
                        <InputGroup class_name="mb-3">
                            <Col>
                                <Form.Control 
                                    aria-label="Text input with dropdown button"
                                    value={formData.pre_mint}
                                    onChange={(e) => handleChange('pre_mint', e.target.value)}
                                    onBlur={() => handleBlur('pre_mint')}
                                    type='number'
                                />
                                {validationErrors.pre_mint && (
                                    <div className="error-message">{validationErrors.pre_mint}</div>
                                )}
                            </Col>
                            <DropdownButton
                                variant="outline-secondary"
                                title=""
                                id="input-group-dropdown-2"
                                align="end"
                            >
                                <Dropdown onSelect={(eventKey) => {handleChange('pre_mint', eventKey); handleDropdownSelect('pre_mint', eventKey)}}>
                                    <Dropdown.Item eventKey='1'>1%</Dropdown.Item>
                                    <Dropdown.Item eventKey='2'>2%</Dropdown.Item>
                                    <Dropdown.Item eventKey='3'>3%</Dropdown.Item>
                                    <Dropdown.Item eventKey='5'>5%</Dropdown.Item>
                                    <Dropdown.Item eventKey='10'>10%</Dropdown.Item>
                                    <Dropdown.Item eventKey='20'>20%</Dropdown.Item>
                                </Dropdown>
                            </DropdownButton>
                        </InputGroup>
                    </Col>
                    <Col sm={12} md={6} className="mb-5">
                        <Form.Label>💱 ETH LP amount <TooltipBody title="Choose how much ETH you want to create the pool with" id="t-1"><Image src={InfoIcon} width="10" /></TooltipBody></Form.Label>
                        <InputGroup class_name="mb-3">
                            <Col>
                                <Form.Control 
                                    aria-label="Text input with dropdown button"
                                    value={formData.eth_lp}
                                    onChange={(e) => handleChange('eth_lp', e.target.value)}
                                    onBlur={() => handleBlur('eth_lp')}
                                    type='number'
                                />
                                {validationErrors.eth_lp && (
                                    <div className="error-message">{validationErrors.eth_lp}</div>
                                )}
                            </Col>
                            <DropdownButton
                                variant="outline-secondary"
                                title=""
                                id="input-group-dropdown-2"
                                align="end"
                            >
                                <Dropdown onSelect={(eventKey) => {handleChange('eth_lp', eventKey); handleDropdownSelect('eth_lp', eventKey)}}>
                                    <Dropdown.Item eventKey='0.3'>0.3 ETH</Dropdown.Item>
                                    <Dropdown.Item eventKey='0.5'>0.5 ETH</Dropdown.Item>
                                    <Dropdown.Item eventKey='1'>1 ETH</Dropdown.Item>
                                    <Dropdown.Item eventKey='2'>2 ETH</Dropdown.Item>
                                    <Dropdown.Item eventKey='3'>3 ETH</Dropdown.Item>
                                    <Dropdown.Item eventKey='5'>5 ETH</Dropdown.Item>
                                </Dropdown>
                            </DropdownButton>
                        </InputGroup>
                    </Col>
                    <Col sm={12} md={6} className="mb-5">
                        <Form.Label>🔒 LP Lock duration <TooltipBody title="Choose for how many days you want to lock LP" id="t-1"><Image src={InfoIcon} width="10" /></TooltipBody></Form.Label>
                        <InputGroup className="mb-3">
                            <Col>
                                <Form.Control 
                                    aria-label="Text input with dropdown button"
                                    value={formData.lp_lock}
                                    onChange={(e) => handleChange('lp_lock', e.target.value)}
                                    onBlur={() => handleBlur('lp_lock')}
                                    type='number'
                                />
                                {validationErrors.lp_lock && (
                                    <div className="error-message">{validationErrors.lp_lock}</div>
                                )}
                            </Col>
                            <DropdownButton
                                variant="outline-secondary"
                                title=""
                                id="input-group-dropdown-2"
                                align="end"
                            >
                                <Dropdown onSelect={(eventKey) => {handleChange('lp_lock', eventKey); handleDropdownSelect('lp_lock', eventKey)}}>
                                    <Dropdown.Item eventKey='30'>30 Days</Dropdown.Item>
                                    <Dropdown.Item eventKey='60'>60 Days</Dropdown.Item>
                                    <Dropdown.Item eventKey='90'>90 Days</Dropdown.Item>
                                    <Dropdown.Item eventKey='120'>120 Days</Dropdown.Item>
                                    <Dropdown.Item eventKey='150'>150 Days</Dropdown.Item>
                                    <Dropdown.Item eventKey='180'>180 Days</Dropdown.Item>
                                </Dropdown>
                            </DropdownButton>
                        </InputGroup>
                    </Col>
                    <Col sm={12} md={6} className="mb-5">
                        <Form.Label>🚫 Max token per wallet <TooltipBody title="Choose the Maximum number of Tokens a Wallet can hold, as a Percentage of Supply:" id="t-1"><Image src={InfoIcon} width="10" /></TooltipBody></Form.Label>
                        <InputGroup className="mb-3">
                            <Col>
                                <Form.Control 
                                    aria-label="Text input with dropdown button"
                                    value={formData.max_token_per_wallet == '0' ? 'No limit': formData.max_token_per_wallet}
                                    onChange={(e) => handleChange('max_token_per_wallet', e.target.value)}
                                    onBlur={() => handleBlur('max_token_per_wallet')}
                                />
                                {validationErrors.max_token_per_wallet && (
                                    <div className="error-message">{validationErrors.max_token_per_wallet}</div>
                                )}
                            </Col>
                            <DropdownButton
                                variant="outline-secondary"
                                title=""
                                id="input-group-dropdown-2"
                                align="end"
                            >
                                <Dropdown onSelect={(eventKey) => {handleChange('max_token_per_wallet', eventKey); handleDropdownSelect('max_token_per_wallet', eventKey)}}>
                                    <Dropdown.Item eventKey='3'>3%</Dropdown.Item>
                                    <Dropdown.Item eventKey='5'>5%</Dropdown.Item>
                                    <Dropdown.Item eventKey='10'>10%</Dropdown.Item>
                                    <Dropdown.Item eventKey='15'>15%</Dropdown.Item>
                                    <Dropdown.Item eventKey='20'>20%</Dropdown.Item>
                                    <Dropdown.Item eventKey='0'>No Limit</Dropdown.Item>
                                </Dropdown>
                            </DropdownButton>
                        </InputGroup>
                    </Col>
                    <Col sm={12} md={6} className="mb-5">
                        <Form.Label>🚫 Max token per TX <TooltipBody title="Choose the Maximum number of Tokens for a Single Transaction, as a Percentage of Supply:" id="t-1"><Image src={InfoIcon} width="10" /></TooltipBody></Form.Label>
                        <InputGroup className="mb-3">
                            <Col>
                                <Form.Control 
                                    aria-label="Text input with dropdown button"
                                    value={formData.max_token_per_transaction == '0' ?  'No limit' : formData.max_token_per_transaction}
                                    onChange={(e) => handleChange('max_token_per_transaction', e.target.value)}
                                    onBlur={() => handleBlur('max_token_per_transaction')}
                                />
                                {validationErrors.max_token_per_transaction && (
                                    <div className="error-message">{validationErrors.max_token_per_transaction}</div>
                                )}
                            </Col>
                            <DropdownButton
                                variant="outline-secondary"
                                title=""
                                id="input-group-dropdown-2"
                                align="end"
                            >   
                                <Dropdown onSelect={(eventKey) => {handleChange('max_token_per_transaction', eventKey); handleDropdownSelect('max_token_per_transaction', eventKey)}}>
                                    <Dropdown.Item eventKey='1'>1%</Dropdown.Item>
                                    <Dropdown.Item eventKey='2'>2%</Dropdown.Item>
                                    <Dropdown.Item eventKey='3'>3%</Dropdown.Item>
                                    <Dropdown.Item eventKey='5'>5%</Dropdown.Item>
                                    <Dropdown.Item eventKey='8'>8%</Dropdown.Item>
                                    <Dropdown.Item eventKey='0'>No Limit</Dropdown.Item>
                                </Dropdown>
                            </DropdownButton>
                        </InputGroup>
                    </Col>
                    <Col sm={12} md={6} className="mb-5">
                        <Form.Label>⏳ Open Trade Delay <TooltipBody title="Choose how many minutes to wait before opening trading" id="t-1"><Image src={InfoIcon} width="10" /></TooltipBody></Form.Label>
                        <InputGroup className="mb-3">
                            <Col>
                                <Form.Control 
                                    aria-label="Text input with dropdown button"
                                    value={formData.open_trade_delay}
                                    onChange={(e) => handleChange('open_trade_delay', e.target.value)}
                                    onBlur={() => handleBlur('open_trade_delay')}
                                    type='number'
                                />
                                {validationErrors.open_trade_delay && (
                                    <div className="error-message">{validationErrors.open_trade_delay}</div>
                                )}
                            </Col>
                            <DropdownButton
                                variant="outline-secondary"
                                title=""
                                id="input-group-dropdown-2"
                                align="end"
                            >
                                <Dropdown onSelect={(eventKey) => {handleChange('open_trade_delay', eventKey); handleDropdownSelect('open_trade_delay', eventKey)}}>
                                    <Dropdown.Item eventKey='15'>15 Min</Dropdown.Item>
                                    <Dropdown.Item eventKey='30'>30 Min</Dropdown.Item>
                                    <Dropdown.Item eventKey='60'>60 Min</Dropdown.Item>
                                    <Dropdown.Item eventKey='90'>90 Min</Dropdown.Item>
                                    <Dropdown.Item eventKey='120'>120 Min</Dropdown.Item>
                                    <Dropdown.Item eventKey='180'>180 Min</Dropdown.Item>
                                </Dropdown>
                            </DropdownButton>
                        </InputGroup>
                    </Col>
                    <Col sm={12} md={6} className="mb-5">
                        <Form.Label>⏳ Remove Limits Delay <TooltipBody title="Choose how long to wait before removing limits for max amount per wallet and transaction" id="t-1"><Image src={InfoIcon} width="10" /></TooltipBody></Form.Label>
                        <InputGroup className="mb-3">
                            <Col>
                                <Form.Control 
                                    aria-label="Text input with dropdown button"
                                    value={formData.remove_limits_delay == '0' ? "Keep limits": formData.remove_limits_delay}
                                    onChange={(e) => handleChange('remove_limits_delay', e.target.value)}
                                    onBlur={() => handleBlur('remove_limits_delay')}
                                    type='number'
                                />
                                {validationErrors.remove_limits_delay && (
                                    <div className="error-message">{validationErrors.remove_limits_delay}</div>
                                )}
                            </Col>
                            <DropdownButton
                                variant="outline-secondary"
                                title=""
                                id="input-group-dropdown-2"
                                align="end"
                            >
                                <Dropdown onSelect={(eventKey) => {handleChange('remove_limits_delay', eventKey); handleDropdownSelect('remove_limits_delay', eventKey)}}>
                                    <Dropdown.Item eventKey='0'>Keep Limits</Dropdown.Item>
                                    <Dropdown.Item eventKey='2'>2</Dropdown.Item>
                                    <Dropdown.Item eventKey='4'>4</Dropdown.Item>
                                    <Dropdown.Item eventKey='6'>6</Dropdown.Item>
                                    <Dropdown.Item eventKey='8'>8</Dropdown.Item>
                                    <Dropdown.Item eventKey='10'>10</Dropdown.Item>
                                </Dropdown>
                            </DropdownButton>
                        </InputGroup>
                    </Col>
                    {/* <Col sm={12} md={12} className="mb-5">
                        <Form.Label>✋ Enable OnlyHumans <TooltipBody title="Default title" id="t-1"><Image src={InfoIcon} width="10" /></TooltipBody></Form.Label>
                        <InputGroup className="mb-3">
                            <Form.Control aria-label="Text input with dropdown button" />
                            <DropdownButton
                                variant="outline-secondary"
                                title=""
                                id="input-group-dropdown-2"
                                align="end"
                            >
                                <Dropdown.Item >10%</Dropdown.Item>
                                <Dropdown.Item >20%</Dropdown.Item>
                                <Dropdown.Item >50%</Dropdown.Item>
                            </DropdownButton>
                        </InputGroup>
                    </Col> */}
                    <Col sm={12} md={6} className="mb-5">
                        <Form.Label>🦉 Twitter <TooltipBody title="Write your project's Twitter link Or hit Set None to leave empty" id="t-1"><Image src={InfoIcon} width="10" /></TooltipBody></Form.Label>
                        <InputGroup className="mb-3">
                            <Col>
                                <Form.Control 
                                    aria-label="Text input with dropdown button"
                                    value={formData.twitter_link}
                                    onChange={(e) => handleChange('twitter_link', e.target.value)}
                                    onBlur={() => handleBlur('twitter_link')}
                                />
                                {validationErrors.twitter_link && (
                                    <div className="error-message">{validationErrors.twitter_link}</div>
                                )}
                            </Col>
                        </InputGroup>
                    </Col>
                    <Col sm={12} md={6} className="mb-5">
                        <Form.Label>💬 Telegram <TooltipBody title="Write your project's Telegram group link Or hit Set None to leave empty" id="t-1"><Image src={InfoIcon} width="10" /></TooltipBody></Form.Label>
                        <InputGroup className="mb-3">
                            <Col>
                                <Form.Control 
                                    aria-label="Text input with dropdown button"
                                    value={formData.telegram_link}
                                    onChange={(e) => handleChange('telegram_link', e.target.value)}
                                    onBlur={() => handleBlur('telegram_link')}
                                />
                                {validationErrors.telegram_link && (
                                    <div className="error-message">{validationErrors.telegram_link}</div>
                                )}
                            </Col>
                        </InputGroup>
                    </Col>
                    <Col sm={12} md={6} className="mb-5">
                        <Form.Label>🌐 Website <TooltipBody title="Write your project's Website link Or hit Set None to leave empty" id="t-1"><Image src={InfoIcon} width="10" /></TooltipBody></Form.Label>
                        <InputGroup className="mb-3">
                            <Col>
                                <Form.Control 
                                    aria-label="Text input with dropdown button"
                                    value={formData.website_link}
                                    onChange={(e) => handleChange('website_link', e.target.value)}
                                    onBlur={() => handleBlur('website_link')}
                                />
                                {validationErrors.website_link && (
                                    <div className="error-message">{validationErrors.website_link}</div>
                                )}
                            </Col>
                        </InputGroup>
                    </Col>
                    <Col sm={12} md={6} className="mb-5">
                        <Form.Label>👾 Discord <TooltipBody title="Write your project's Discord link Or hit Set None to leave empty" id="t-1"><Image src={InfoIcon} width="10" /></TooltipBody></Form.Label>
                        <InputGroup className="mb-3">
                            <Col>
                                <Form.Control 
                                    aria-label="Text input with dropdown button"
                                    value={formData.discord_link}
                                    onChange={(e) => handleChange('discord_link', e.target.value)}
                                    onBlur={() => handleBlur('discord_link')}
                                />
                                {validationErrors.discord_link && (
                                    <div className="error-message">{validationErrors.discord_link}</div>
                                )}
                            </Col>
                        </InputGroup>
                    </Col>
                </Row>
                <hr className="secondary-hr my-5" />
                <Row>
                    <Col sm={12} md={3} className="mx-auto mb-5">
                        <Button className="main-button w-100" onClick={handleReviewButton} disabled={hasValidationErrors()}>Review token details</Button>
                    </Col>
                </Row>
            </div>
        )}
    else {
        return renderDeploymentSummary();
    }
}