import { Table, Accordion, Row, Col, Button } from "react-bootstrap";
import { useState, useEffect } from "react";
import { useMain } from "../../context/MainContext";
import { useAuth } from "../../context/AuthContext";

export const DeployedTabContent = () => {

    const { getDeployedTokens, renounceTokenOwnership } = useMain();
    const { accessToken } = useAuth();
    const [tokens, setTokens] = useState([])

    useEffect(() => {
        fetchTokens();
    }, [accessToken]);

    const fetchTokens = async () => {
        console.log("access token: ", accessToken)
        if (accessToken !== 'null') {
            try {
                const response = await getDeployedTokens();
                if(response != undefined){
                    setTokens(response);
                }
            } catch (error) {
                console.error("Error fetching tokens:", error);
                setTokens([]);
        }
        }  
    };

    const handleRenounceButton = async (tokenId) => {
        try {
            await renounceTokenOwnership(tokenId);
        } catch (error) {
            console.error("Error deploying token:", error);
        }
    };

    const copyToClipboard = text => {
        navigator.clipboard.writeText(text);
    };

    return (
        <div className="DeployedTabContent p-3 p-md-5">
            <Table responsive>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Name</th>
                        <th>Symbol</th>
                        <th>Supply</th>
                        <th>Deployer</th>
                        <th>Network</th>
                    </tr>
                </thead>
                <tbody>
                    {tokens.map((token, index) => (
                        <tr key={/*token.id ||*/ index}>
                            <td colSpan={6}>
                                <Accordion defaultActiveKey="0">
                                    <Accordion.Item eventKey={index}>
                                        <Accordion.Header>
                                            <table className="w-100 text-white">
                                                <tbody>
                                                    <tr>
                                                        <td>{index + 1}</td>
                                                        <td>{token.name}</td>
                                                        <td>{token.symbol}</td>
                                                        <td>{token.supply}</td>
                                                        <td>{token.deployer_wallet}</td>
                                                        <td>{token.network}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            {/* Replace the spans with actual token data */}
                                            <Row>
                                                {/* Repeat for each property of the token */}
                                                <Col sm={12} md={6} className="my-3">
                                                    <span className="td-title">💲 Symbol : </span>
                                                    <span className="td-value">{token.symbol}</span>
                                                </Col>
                                                <Col sm={12} md={6} className="my-3">
                                                    <span className="td-title">🔠 Name : </span>
                                                    <span className="td-value">{token.name}</span>
                                                </Col>
                                                 <Col sm={12} md={6} className="my-3">
                                                     <span className="td-title">🔢 Supply : </span>
                                                     <span className="td-value">{token.supply}</span>
                                                 </Col>
                                                 <Col sm={12} md={6} className="my-3">
                                                     <span className="td-title">🔤 Class Name : </span>
                                                     <span className="td-value">{token.class_name}</span>
                                                 </Col>
                                                 <Col sm={12} md={6} className="my-3">
                                                     <span className="td-title">🟢 Initial Buy Tax : </span>
                                                     <span className="td-value">{token.initial_buy_tax}</span>
                                                 </Col>
                                                 <Col sm={12} md={6} className="my-3">
                                                     <span className="td-title">🔴 Initial Sell Tax : </span>
                                                     <span className="td-value">{token.initial_sell_tax}</span>
                                                 </Col>
                                                 <Col sm={12} md={6} className="my-3">
                                                     <span className="td-title">⏱ Initial Tax Threshold : </span>
                                                     <span className="td-value">{token.initial_tax_threshold}</span>
                                                 </Col>
                                                 <Col sm={12} md={6} className="my-3">
                                                     <span className="td-title">🟢 Buy Tax : </span>
                                                     <span className="td-value">{token.buy_tax}</span>
                                                 </Col>
                                                 <Col sm={12} md={6} className="my-3">
                                                     <span className="td-title">🔴 Sell Tax : </span>
                                                     <span className="td-value">{token.sell_tax}</span>
                                                 </Col>
                                                 <Col sm={12} md={6} className="my-3">
                                                     <span className="td-title">🔥 Burn : </span>
                                                     <span className="td-value">{token.burn}</span>
                                                 </Col>
                                                 <Col sm={12} md={6} className="my-3">
                                                     <span className="td-title">📩 Tax Receiver : </span>
                                                     <span className="td-value" style={{ cursor: 'pointer', color:'blueviolet'}} onClick={() => copyToClipboard(token.tax_receiver)}>{`${token.tax_receiver.slice(0, 5)}...${token.tax_receiver.slice(-3)}`}</span>
                                                 </Col>
                                                 <Col sm={12} md={6} className="my-3">
                                                     <span className="td-title">💰 Pre Mint Supply : </span>
                                                     <span className="td-value">{token.pre_mint}</span>
                                                 </Col>
                                                 <Col sm={12} md={6} className="my-3">
                                                     <span className="td-title">💱 ETH LP amount : </span>
                                                     <span className="td-value">{token.eth_lp}</span>
                                                 </Col>
                                                 <Col sm={12} md={6} className="my-3">
                                                     <span className="td-title">🔒 LP Lock duration : </span>
                                                     <span className="td-value">{token.lp_lock}</span>
                                                 </Col>
                                                 <Col sm={12} md={6} className="my-3">
                                                     <span className="td-title">🚫 Max token per wallet : </span>
                                                     <span className="td-value">{token.max_token_per_wallet}</span>
                                                 </Col>
                                                 <Col sm={12} md={6} className="my-3">
                                                     <span className="td-title">🚫 Max token per TX : </span>
                                                     <span className="td-value">{token.max_token_per_transaction}</span>
                                                 </Col>
                                                 <Col sm={12} md={6} className="my-3">
                                                     <span className="td-title">⏳ Open Trade Delay : </span>
                                                     <span className="td-value">{token.open_trade_delay}</span>
                                                 </Col>
                                                 <Col sm={12} md={6} className="my-3">
                                                     <span className="td-title">⏳ Remove Limits Delay : </span>
                                                     <span className="td-value">{token.remove_limits_delay}</span>
                                                 </Col>
                                                 {/* <Col sm={12} md={6} className="my-3">
                                                     <span className="td-title">✋ Enable OnlyHumans : </span>
                                                     <span className="td-value">{token.network == 'ethereum' ? '✅' : '❌'}</span>
                                                 </Col> */}
                                                 <Col sm={12} md={6} className="my-3">
                                                     <span className="td-title">#️⃣ Tx hash : </span>
                                                     <span className="td-value" style={{ cursor: 'pointer', color:'blueviolet'}} onClick={() => copyToClipboard(token.tx_hash)}>{`${token.tx_hash.slice(0, 5)}...${token.tx_hash.slice(-3)}`}</span>
                                                 </Col>
                                                 <Col sm={12} md={6} className="my-3">
                                                     <span className="td-title">🏠 Address : </span>
                                                     <span className="td-value" style={{ cursor: 'pointer', color:'blueviolet'}} onClick={() => copyToClipboard(token.address)}>{`${token.address.slice(0, 5)}...${token.address.slice(-3)}`}</span>
                                                 </Col>
                                                 <Col sm={12} md={6} className="my-3">
                                                     <span className="td-title">🅿️ Pair address : </span>
                                                     <span className="td-value" style={{ cursor: 'pointer', color:'blueviolet'}} onClick={() => copyToClipboard(token.pair_address)}>{`${token.pair_address.slice(0, 5)}...${token.pair_address.slice(-3)}`}</span>
                                                 </Col>
                                                 <Col sm={12} md={6} className="my-3">
                                                     <span className="td-title">🌐 Network : </span>
                                                     <span className="td-value">{token.network}</span>
                                                 </Col>
                                                 <Col sm={12} md={6} className="my-3">
                                                     <span className="td-title">🦉 Twitter : </span>
                                                     <span className="td-value">{token.twitter_link}</span>
                                                 </Col>
                                                 <Col sm={12} md={6} className="my-3">
                                                     <span className="td-title">💬 Telegram : </span>
                                                     <span className="td-value">{token.telegram_link}</span>
                                                 </Col>
                                                 <Col sm={12} md={6} className="my-3">
                                                     <span className="td-title">🌐 Website : </span>
                                                     <span className="td-value">{token.website_link}</span>
                                                 </Col>
                                                 <Col sm={12} md={6} className="my-3">
                                                     <span className="td-title">👾 Discord : </span>
                                                     <span className="td-value">{token.discord_link}</span>
                                                 </Col>
                                                 <Col sm={12} md={6} className="my-3">
                                                     <span className="td-title">ℹ️ Status : </span>
                                                     <span className="td-value">{!token.state_completed_with_errors ? "Successfully deployed" : token.state_error_message}</span>
                                                 </Col>
                                            </Row>
                                            {!token.state_renounce_ownership && (
                                            <Row>
                                                <Col sm={12} md={3} className="mx-auto mb-5">
                                                    <Button className="main-button w-100" disabled={token.state_renounce_ownership} onClick={() => handleRenounceButton(token.id)}>Renounce ownership</Button>
                                                </Col>
                                            </Row>
                                            )
                                            }
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    );

}