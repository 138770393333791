import axios from "axios";

export async function getNonce(address) {
  try {
    return (await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/authentication/nonce_sign_request`,
      {
        address: address,
      }
    )).data.nonce;
  } catch (error) {
    console.error(error);
  }
}

export async function verifyNonce(address, nonce, signature) {
  try {
    return await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/authentication/nonce_sign_verify`,
      {
        address: address,
        nonce: nonce,
        signature: signature
      }
    );
  } catch (error) {
    console.error(error);
  }
}

export async function getCurrentUser() {
  try {
    return await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/authentication/user`
    );
  } catch (error) {
    console.error(error);
  }
}

export async function updateCurrentUser(
  email = undefined,
  address = undefined,
  password = undefined, 
  role = undefined, 
  telegram_wallet_current = undefined,
  telegram_token_current = undefined
) {
  try {
    let requestBody = {
      'email': email,
      'address': address,
      'password': password,
      'role': role,
      'telegram_wallet_current': telegram_wallet_current,
      'telegram_wallet_current': telegram_token_current
    };

    // Filter out undefined properties
    requestBody = Object.entries(requestBody)
    .filter(([key, value]) => value !== undefined)
    .reduce((obj, [key, value]) => {
      obj[key] = value;
      return obj;
    }, {});

    return await axios.put(`${process.env.REACT_APP_BACKEND_URL}/authentication/user`, requestBody);
  } catch (error) {
    console.error(error);
  }
}

export async function getUserWallets() {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/authentication/wallet`
    );
    console.log("Wallets data: ", res.data)
    return res.data
  } catch (error) {
    console.error(error);
  }
}

export async function generateWallet() {
  try {
    return await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/authentication/wallet/generate`
    );
  } catch (error) {
    console.error(error);
  }
}

export async function importWallet(privateKey) {
  try {
    return await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/authentication/wallet/import`,
      {
        private_key: privateKey
      }
    );
  } catch (error) {
    console.error(error);
  }
}

export async function getDeployedTokens() {
  try {
    return await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/bot/token`
    );
  } catch (error) {
    console.error(error);
  }
}

export async function getCurrentToken() {
  try {
    const user = await getCurrentUser()
    const tokenId = user.data.telegram_token_current.id
    return await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/bot/token/${tokenId}/`
    );
  } catch (error) {
    console.error(error);
  }
}

// export async function createToken(
//   symbol = '',
//   name = '',
//   class_name = '',
//   supply = 0,
//   pre_mint = 0,    
//   tax_receiver = "",
//   initial_buy_tax = 0,
//   initial_sell_tax = 0,
//   initial_tax_threshold = 0,
//   buy_tax = 0,
//   sell_tax = 0,
//   max_token_per_wallet = 0,
//   max_token_per_transaction = 0,
//   burn = 0,
//   eth_lp = 0.001,
//   lp_lock = 30,
//   telegram_link = '',
//   discord_link = '',
//   twitter_link = '',
//   website_link = '',
//   open_trade_delay = 0,
//   tax_admin_percentage = 100,
//   swap_threshold = 0.1 * 10**18,
//   remove_limits_delay = 0,
//   network = 'ethereum',
//   whitelist_delay = 0
// ) {
//   try {
//     const user = await getCurrentUser();
//     const deployer_wallet = user.data.telegram_wallet_current;

//     const token = await axios.post(
//       `${process.env.REACT_APP_BACKEND_URL}/bot/token`,
//       {
//         //user: user.data.id,   not needed because it is set in the backend
//         symbol: symbol,
//         name: name,
//         class_name: class_name,
//         supply: supply,
//         pre_mint: pre_mint,    
//         tax_receiver: tax_receiver,
//         initial_buy_tax: initial_buy_tax,
//         initial_sell_tax: initial_sell_tax,
//         initial_tax_threshold: initial_tax_threshold,
//         buy_tax: buy_tax,
//         sell_tax: sell_tax,
//         max_token_per_wallet: max_token_per_wallet,
//         max_token_per_transaction: max_token_per_transaction,
//         burn: burn,
//         eth_lp: eth_lp,
//         lp_lock: lp_lock,
//         telegram_link: telegram_link,
//         discord_link: discord_link,
//         twitter_link: twitter_link,
//         website_link: website_link,
//         open_trade_delay: open_trade_delay,
//         tax_admin_percentage: tax_admin_percentage,
//         swap_threshold: swap_threshold,
//         remove_limits_delay: remove_limits_delay,
//         network: network,
//         whitelist_delay: whitelist_delay,
//         deployer_wallet: deployer_wallet,
//       }
//     );

//     await updateCurrentUser({ telegram_token_current: token.data.id });    //move in backend?

//     return token;
//   } catch (error) {
//     console.error(error);
//   }
// }

export async function updateCurrentToken(
  symbol = undefined,
  name = undefined,
  class_name = undefined,
  supply = undefined,
  pre_mint = undefined,    
  tax_receiver = undefined,
  initial_buy_tax = undefined,
  initial_sell_tax = undefined,
  initial_tax_threshold = undefined,
  buy_tax = undefined,
  sell_tax = undefined,
  max_token_per_wallet = undefined,
  max_token_per_transaction = undefined,
  burn = undefined,
  eth_lp = undefined,
  lp_lock = undefined,
  telegram_link = undefined,
  discord_link = undefined,
  twitter_link = undefined,
  website_link = undefined,
  open_trade_delay = undefined,
  tax_admin_percentage = undefined,
  swap_threshold = undefined,
  remove_limits_delay = undefined,
  network = undefined,
  whitelist_delay = undefined,
) {
  try {
    const user = await getCurrentUser();
    const tokenId = user.data.telegram_token_current.id;

    let requestBody = {
      symbol,
      name,
      class_name,
      supply,
      pre_mint,
      tax_receiver,
      initial_buy_tax,
      initial_sell_tax,
      initial_tax_threshold,
      buy_tax,
      sell_tax,
      max_token_per_wallet,
      max_token_per_transaction,
      burn,
      eth_lp,
      lp_lock,
      telegram_link,
      discord_link,
      twitter_link,
      website_link,
      open_trade_delay,
      tax_admin_percentage,
      swap_threshold,
      remove_limits_delay,
      network,
      whitelist_delay
    };

    // Filter out undefined properties
    requestBody = Object.entries(requestBody)
      .filter(([key, value]) => value !== undefined)
      .reduce((obj, [key, value]) => {
        obj[key] = value;
        return obj;
      }, {});

    return await axios.put(`${process.env.REACT_APP_BACKEND_URL}/bot/token/${tokenId}/`, requestBody);
  } catch (error) {
    console.error(error);
  }
}

export async function deployCurrentToken() {
  try {
    const user = await getCurrentUser()
    const tokenId = user.data.telegram_token_current.id
    return await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/bot/token/deploy/${tokenId}/`
    );
  } catch (error) {
    console.error(error);
  }
}

