import React, { createContext, useContext, useState, useEffect } from "react";
import { getNonce, verifyNonce } from "../lib/auth";
import { useAccount } from "wagmi";
import { signMessage } from "@wagmi/core";
import axios from "axios";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const baseURL = process.env.REACT_APP_BACKEND_URL;

  const { address } = useAccount();

  const [accessToken, setAccessToken] = useState(() => localStorage.getItem("access_token"));
  const [refreshToken, setRefreshToken] = useState(() => localStorage.getItem("refresh_token"));
  const [nonce, setNonce] = useState(null);

  useEffect(() => {
    localStorage.setItem("access_token", accessToken);
    localStorage.setItem("refresh_token", refreshToken);
  }, [accessToken, refreshToken])

  const web3Login = async () => {
    if (address) {
      let nonce_requested = await getNonce(address);
      let signed = await signMessage({ message: nonce_requested });
      let res = await verifyNonce(address, nonce_requested, signed);
      setAccessToken(res.data.access_token);
      setRefreshToken(res.data.refresh_token);
    }
  };

  const logout = () => {
    setAccessToken(null);
    setRefreshToken(null);
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    window.location.reload()
  };

  const authAxios = axios.create({
    baseURL: baseURL,
  });

  authAxios.interceptors.request.use(
    (config) => {
      if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
      }
      console.log(config.headers.Authorization)
      return config;
    },
    (error) => Promise.reject(error)
  );

  authAxios.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;

      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        const newAccessToken = await refreshAccessToken(refreshToken);
        if (newAccessToken) {
          setAccessToken(newAccessToken);

          originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
          console.log(originalRequest);

          return authAxios(originalRequest);
        }
      }

      return Promise.reject(error);
    }
  );

  const refreshAccessToken = async (refreshToken) => {
    try {
      const response = await axios.post(
        `${baseURL}/authentication/login/refresh`,
        {
          refresh: refreshToken,
        }
      );
      return response.data.access;
    } catch (error) {
      console.error("Error refreshing token", error);
      return null;
    }
  };

  return (
    <AuthContext.Provider
      value={{
        authAxios,
        accessToken,
        setAccessToken,
        refreshToken,
        setRefreshToken,
        nonce,
        setNonce,
        web3Login,
        address,
        accessToken,
        logout
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
