import React, { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";
import { useAuth } from "./AuthContext";

const MainContext = createContext();

export const MainProvider = ({ children }) => {

  const { authAxios } = useAuth();

  const [currentWallet, setCurrentWallet] = useState({});
  const [currentNetwork, setCurrentNetwork] = useState('');
  const [balances, setBalances] = useState({ eth_balance: 0, espr_balance: 0, tier: '' });

  // useEffect(() => {
  //     console.log("Updated current wallet:", currentWallet);
  // }, [currentWallet]);

  const changeCurrentWalletState = (wallet) => {
      setCurrentWallet(wallet);
  };

  const changeCurrentNetworkState = (network) => {
      setCurrentNetwork(network);
  };

  const changeBalancesState = (balances) => {
      setBalances(balances);
  };

  async function getCurrentUser() {
    try {
      const res = await authAxios.get(`authentication/user`);
      return res.data;
    } catch (error) {
      console.error(error);
    }
  }

  async function getUserBalances() {
    try {
      const res = await authAxios.get(`authentication/user/balances`);
      return res.data;
    } catch (error) {
      console.error(error);
    }
  }

  async function getNetworkChoices() {
    try {
      const response = await authAxios.get(`authentication/network-choices`);
      const networks = response.data.network_choices.map(choice => choice[0]);
      return networks;
    } catch (error) {
      console.error("Error fetching network choices:", error);
      return [];
    }
  }

  async function updateCurrentUser(
    email = undefined,
    address = undefined,
    password = undefined,
    role = undefined,
    telegram_wallet_current = undefined,
    telegram_token_current = undefined
  ) {
    try {
      let requestBody = {
        email: email,
        address: address,
        password: password,
        role: role,
        telegram_wallet_current: telegram_wallet_current,
        telegram_token_current: telegram_token_current,
      };

      requestBody = Object.entries(requestBody)
        .filter(([key, value]) => value !== undefined)
        .reduce((obj, [key, value]) => {
          obj[key] = value;
          return obj;
        }, {});

      return await authAxios.put(
        `authentication/user`,
        requestBody
      );
    } catch (error) {
      console.error(error);
    }
  }

  async function updateCurrentWallet(telegram_wallet_current) {
    try {
      return await authAxios.put(
        `authentication/user`,
        {telegram_wallet_current: telegram_wallet_current}
      );
    } catch (error) {
      console.error(error);
    }
  }

  async function updateCurrentNetwork(network) {
    try {
      return await authAxios.put(
        `authentication/user`,
        {telegram_network: network}
      );
    } catch (error) {
      console.error(error);
    }
  }

  async function getUserWallets() {
    try {
      const res = await authAxios.get(`authentication/wallet`);
      return res.data;
    } catch (error) {
      console.error(error);
    }
  }

  async function getWalletById(walletId) {
    try {
      const res = await authAxios.get(`authentication/wallet/${walletId}/`);
      return res.data;
    } catch (error) {
      console.error(error);
    }
  }

  async function getCurrentWallet() {
    try {
      const user = await getCurrentUser();
      const walletId = user.telegram_wallet_current;//.id;
      const res = await authAxios.get(`authentication/wallet/${walletId}/`);
      return res.data;
    } catch (error) {
      console.error(error);
    }
  }

  async function getCurrentWalletId() {
    try {
      const user = await getCurrentUser();
      return user.telegram_wallet_current;
    } catch (error) {
      console.error(error);
    }
  }

  async function getCurrentNetwork() {
    try {
      const user = await getCurrentUser();
      return user.telegram_network;
    } catch (error) {
      console.error(error);
    }
  }

  async function generateWallet() {
    try {
      const res = await authAxios.get(`authentication/wallet/generate`);
      return res.data;
    } catch (error) {
      console.error(error);
    }
  }

  async function importWallet(privateKey) {
    try {
      return await authAxios.post(
        `authentication/wallet/import`,
        {private_key: privateKey}
      );
    } catch (error) {
      console.error(error);
    }
  }

  async function deleteWallet(walletId) {
    try {
      await authAxios.delete(
        `authentication/wallet/${walletId}/`
      );
    } catch (error) {
      console.error(error);
    }
  }

  async function getDeployedTokens() {
    try {
      const res = await authAxios.get(`bot/token`);
      return res.data;
    } catch (error) {
      console.error(error);
    }
  }

  async function getCurrentToken() {
    try {
      const user = await getCurrentUser();
      const tokenId = user.telegram_token_current;
      const res = await authAxios.get(`bot/token/${tokenId}/`);
      return res.data;
    } catch (error) {
      console.error(error);
    }
  }

  async function getCurrentTokenStatus() {
    try {
      const user = await getCurrentUser();
      const tokenId = user.telegram_token_current;
      const res = await authAxios.get(`bot/token/${tokenId}/`);
      return {
        state_compile: res.data.state_compile,
        state_deploy: res.data.state_deploy,
        state_pair_address: res.data.state_pair_address,
        state_approve_router: res.data.state_approve_router,
        state_add_liquidity: res.data.state_add_liquidity,
        state_remove_limits: res.data.state_remove_limits,
        state_approve_locker: res.data.state_approve_locker,
        state_lock_lp: res.data.state_lock_lp,
        state_renounce_ownership: res.data.state_renounce_ownership,
        state_verify: res.data.state_verify,
        state_completed: res.data.state_completed,
        state_completed_with_errors: res.data.state_completed_with_errors,
        state_error_message: res.data.state_error_message,
        tx_hash: res.data.tx_hash,
        address: res.data.address
      };
    } catch (error) {
      console.error(error);
    }
  }

  // async function updateCurrentToken(
  //   symbol = undefined,
  //   name = undefined,
  //   class_name = undefined,
  //   supply = undefined,
  //   pre_mint = undefined,
  //   tax_receiver = undefined,
  //   initial_buy_tax = undefined,
  //   initial_sell_tax = undefined,
  //   initial_tax_threshold = undefined,
  //   buy_tax = undefined,
  //   sell_tax = undefined,
  //   max_token_per_wallet = undefined,
  //   max_token_per_transaction = undefined,
  //   burn = undefined,
  //   eth_lp = undefined,
  //   lp_lock = undefined,
  //   telegram_link = undefined,
  //   discord_link = undefined,
  //   twitter_link = undefined,
  //   website_link = undefined,
  //   open_trade_delay = undefined,
  //   tax_admin_percentage = undefined,
  //   swap_threshold = undefined,
  //   remove_limits_delay = undefined,
  //   network = undefined,
  //   whitelist_delay = undefined
  // ) {
  //   try {
  //     const user = await getCurrentUser();
  //     const tokenId = user.data.telegram_token_current.id;

  //     let requestBody = {
  //       symbol: symbol,
  //       name: name,
  //       class_name: class_name,
  //       supply: supply,
  //       pre_mint: pre_mint,
  //       tax_receiver: tax_receiver,
  //       initial_buy_tax: initial_buy_tax,
  //       initial_sell_tax: initial_sell_tax,
  //       initial_tax_threshold: initial_tax_threshold,
  //       buy_tax: buy_tax,
  //       sell_tax: sell_tax,
  //       max_token_per_wallet: max_token_per_wallet,
  //       max_token_per_transaction: max_token_per_transaction,
  //       burn: burn,
  //       eth_lp: eth_lp,
  //       lp_lock: lp_lock,
  //       telegram_link: telegram_link,
  //       discord_link: discord_link,
  //       twitter_link: twitter_link,
  //       website_link: website_link,
  //       open_trade_delay: open_trade_delay,
  //       tax_admin_percentage: tax_admin_percentage,
  //       swap_threshold: swap_threshold,
  //       remove_limits_delay: remove_limits_delay,
  //       network: network,
  //       whitelist_delay: whitelist_delay,
  //     };

  //     requestBody = Object.entries(requestBody)
  //       .filter(([key, value]) => value !== undefined)
  //       .reduce((obj, [key, value]) => {
  //         obj[key] = value;
  //         return obj;
  //       }, {});

  //     return await authAxios.put(
  //       `bot/token/${tokenId}/`,
  //       requestBody
  //     );
  //   } catch (error) {
  //     console.error(error);
  //   }
  // }

  // async function updateCurrentToken(
  //   symbol = undefined,
  //   name = undefined,
  //   class_name = undefined,
  //   supply = undefined,
  //   pre_mint = undefined,
  //   tax_receiver = undefined,
  //   initial_buy_tax = undefined,
  //   initial_sell_tax = undefined,
  //   initial_tax_threshold = undefined,
  //   buy_tax = undefined,
  //   sell_tax = undefined,
  //   max_token_per_wallet = undefined,
  //   max_token_per_transaction = undefined,
  //   burn = undefined,
  //   eth_lp = undefined,
  //   lp_lock = undefined,
  //   telegram_link = undefined,
  //   discord_link = undefined,
  //   twitter_link = undefined,
  //   website_link = undefined,
  //   open_trade_delay = undefined,
  //   //tax_admin_percentage = undefined,
  //   //swap_threshold = undefined,
  //   remove_limits_delay = undefined,
  //   network = undefined,
  //   //whitelist_delay = undefined
  // ) {
  //   try {
  //     const user = await getCurrentUser();
  //     const tokenId = user.data.telegram_token_current.id;

  //     let requestBody = {
  //       symbol: symbol,
  //       name: name,
  //       class_name: class_name,
  //       supply: supply,
  //       pre_mint: pre_mint,
  //       tax_receiver: tax_receiver,
  //       initial_buy_tax: initial_buy_tax,
  //       initial_sell_tax: initial_sell_tax,
  //       initial_tax_threshold: initial_tax_threshold,
  //       buy_tax: buy_tax,
  //       sell_tax: sell_tax,
  //       max_token_per_wallet: max_token_per_wallet,
  //       max_token_per_transaction: max_token_per_transaction,
  //       burn: burn,
  //       eth_lp: eth_lp,
  //       lp_lock: lp_lock,
  //       telegram_link: telegram_link,
  //       discord_link: discord_link,
  //       twitter_link: twitter_link,
  //       website_link: website_link,
  //       open_trade_delay: open_trade_delay,
  //       //tax_admin_percentage: tax_admin_percentage,
  //       //swap_threshold: swap_threshold,
  //       remove_limits_delay: remove_limits_delay,
  //       network: network,
  //       //whitelist_delay: whitelist_delay,
  //     };

  //     // requestBody = Object.entries(requestBody)
  //     //   .filter(([key, value]) => value !== undefined)
  //     //   .reduce((obj, [key, value]) => {
  //     //     obj[key] = value;
  //     //     return obj;
  //     //   }, {});

  //     return await authAxios.put(
  //       `bot/token/${tokenId}/`,
  //       requestBody
  //     );
  //   } catch (error) {
  //     console.error(error);
  //   }
  // }

  // async function updateCurrentToken(
  //   symbol = undefined,
  //   name = undefined,
  //   class_name = undefined,
  //   supply = undefined,
  //   pre_mint = undefined,
  //   tax_receiver = undefined,
  //   initial_buy_tax = undefined,
  //   initial_sell_tax = undefined,
  //   initial_tax_threshold = undefined,
  //   buy_tax = undefined,
  //   sell_tax = undefined,
  //   max_token_per_wallet = undefined,
  //   max_token_per_transaction = undefined,
  //   burn = undefined,
  //   eth_lp = undefined,
  //   lp_lock = undefined,
  //   telegram_link = undefined,
  //   discord_link = undefined,
  //   twitter_link = undefined,
  //   website_link = undefined,
  //   open_trade_delay = undefined,
  //   remove_limits_delay = undefined,
  //   network = undefined,
  // ) {
  //   try {
  //     const user = await getCurrentUser();
  //     const tokenId = user.data.telegram_token_current.id;

  //     let requestBody = {};

  //     if(symbol) {requestBody.symbol = symbol}
  //     if(name) {requestBody.name = name}
  //     if(class_name) {requestBody.class_name = class_name}
  //     if(supply) {requestBody.supply = supply}
  //     if(pre_mint) {requestBody.pre_mint = pre_mint}
  //     if(tax_receiver) {requestBody.tax_receiver = tax_receiver}
  //     if(initial_buy_tax) {requestBody.initial_buy_tax = initial_buy_tax}
  //     if(initial_sell_tax) {requestBody.initial_sell_tax = initial_sell_tax}
  //     if(initial_tax_threshold) {requestBody.initial_tax_threshold = initial_tax_threshold}
  //     if(buy_tax) {requestBody.buy_tax = buy_tax}
  //     if(sell_tax) {requestBody.sell_tax = sell_tax}
  //     if(max_token_per_wallet) {requestBody.max_token_per_wallet = max_token_per_wallet}
  //     if(max_token_per_transaction) {requestBody.max_token_per_transaction = max_token_per_transaction}
  //     if(burn) {requestBody.burn = burn}
  //     if(eth_lp) {requestBody.eth_lp = eth_lp}
  //     if(lp_lock) {requestBody.lp_lock = lp_lock}
  //     if(telegram_link) {requestBody.telegram_link = telegram_link}
  //     if(discord_link) {requestBody.discord_link = discord_link}
  //     if(twitter_link) {requestBody.twitter_link = twitter_link}
  //     if(website_link) {requestBody.website_link = website_link}
  //     if(open_trade_delay) {requestBody.open_trade_delay = open_trade_delay}
  //     if(remove_limits_delay) {requestBody.remove_limits_delay = remove_limits_delay}
  //     if(network) {requestBody.network = network}
    

  //     return await authAxios.put(
  //       `bot/token/${tokenId}/`,
  //       requestBody
  //     );
  //   } catch (error) {
  //     console.error(error);
  //   }
  // }



  // async function updateCurrentToken(tokenData = {}) {
  //   try {
  //       const user = await getCurrentUser();
  //       const tokenId = user.telegram_token_current;

  //       //console.log("token data: ", tokenData)
  //       const requestBody = {};
  //       for (const key in tokenData) {
  //           if (tokenData[key] !== undefined) {
  //               requestBody[key] = tokenData[key];
  //           }
  //       }
  //       //console.log("request body: ", requestBody)

  //       return await authAxios.put(
  //           `bot/token/${tokenId}/`,
  //           requestBody
  //       );
  //   } catch (error) {
  //       console.error(error);
  //   }
  // }

  function extractErrorMessage(errorStr) {
    // First pattern: Handles format like {'__all__': ['Error message']}
    let match = errorStr.match(/'__all__': \['(.*?)'\]/);
    if (match) return match[1];

    // Second pattern: Handles format like {'field': [ErrorDetail(string='Error message', code='error_code')]}
    match = errorStr.match(/ErrorDetail\(string='(.*?)',/);
    return match ? match[1] : "Unknown error";
  } 

  async function updateCurrentToken(tokenData = {}) {
    try {
        const user = await getCurrentUser();
        const tokenId = user.telegram_token_current;
        const requestBody = {};

        for (const key in tokenData) {
            if (tokenData[key] !== undefined) {
                requestBody[key] = tokenData[key];
            }
        }

        const response = await authAxios.put(`bot/token/${tokenId}/`, requestBody);
        return response;
    } catch (error) {
        console.error("Error in updateCurrentToken: ", error);
        let errorString = extractErrorMessage(error.response.data.error)
        //console.log("Error in updateCurrentToken: ", errorString);
        throw new Error(errorString || "An error occurred while updating the token");
    }
  }



  async function deployCurrentToken() {
    try {
      const user = await getCurrentUser();
      const tokenId = user.data.telegram_token_current.id;
      const res = await authAxios.get(`bot/token/deploy/${tokenId}/`);
      return res.data;
    } catch (error) {
      console.error(error);
    }
  }

  async function renounceTokenOwnership(tokenId) {
    try {
      const res = await authAxios.get(`bot/token/renounce/${tokenId}/`);
      return res.data;
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <MainContext.Provider
      value={
        {
          currentWallet,
          currentNetwork,
          balances,
          changeCurrentWalletState,
          changeCurrentNetworkState,
          changeBalancesState,

          getCurrentUser,
          getUserBalances,
          updateCurrentUser,
          updateCurrentWallet,
          updateCurrentNetwork,
          getUserWallets,
          getCurrentWallet,
          getCurrentWalletId,
          getWalletById,
          generateWallet,
          importWallet,
          deleteWallet,
          getDeployedTokens,
          getCurrentToken,
          getCurrentTokenStatus,
          updateCurrentToken,
          deployCurrentToken,
          renounceTokenOwnership,
          getNetworkChoices,
          getCurrentNetwork
        }
      }
    >
      {children}
    </MainContext.Provider>
  );
};

export const useMain = () => {
  const context = useContext(MainContext);
  if (!context) {
    throw new Error("useMain must be used within a MainProvider");
  }
  return context;
};
